<template>
  <ModalAddressWrapper
    class="address-edit-modal"
    right-name="Форма"
    :is-show-modal="isShowModal"
    :title="title"
    subtitle="Укажите на карте или введите вручную"
    :zoom="zoom"
    :map-settings="mapSettings"
    :map-options="mapOptions"
    :map-controls="mapControls"
    :coords="computedCoords"
    @close="closeModalHandler"
    @mapClick="onMapClick"
    @mapDragMarker="onDragMapMarker"
  >
    <form class="address-edit-modal__form" @submit.prevent="onSubmit">
      <div v-if="getCountriesList.length > 1" class="address-edit-modal__form-row">
        <CustomSelect
          v-model="currentCountry"
          class="address-edit-modal__form-column address-edit-modal__form-column--100"
          label-option-name="label"
          :options="getCountriesList"
          placeholder="Выберите страну"
          small
        />
      </div>
      <div class="address-edit-modal__form-row">
        <CustomSuggestion
          class="address-edit-modal__form-column address-edit-modal__form-column--100"
          title="Введите адрес"
          :value="inputAddress"
          :options="options"
          :items="cities"
          :error="cityError"
          @input="onCityInputChange"
          @selected="onCitySelected"
          @mounted="onCityMounted"
        >
          <template #item="{ item }">
            {{ item.value }}
          </template>
        </CustomSuggestion>
      </div>

      <CustomInput
        v-model="address.comment"
        class="address-edit-modal__form-textarea"
        tag="textarea"
        rows="3"
        :show-message="true"
        message="Например, как быстрее к вам добраться или как лучше с вами связаться"
        float
        data-test="checkout-address-comment"
      >
        Комментарии для курьера
      </CustomInput>

      <div class="address-edit-modal__form-submit">
        <Button
          class="personal-form__submit"
          type="submit"
          full
          :disabled="submitPending"
        >
          <Spinner v-if="submitPending" theme="simple" width="36px" height="36px" show />
          <template v-else>
            {{ btnText }}
          </template>
        </Button>
      </div>
    </form>
  </ModalAddressWrapper>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

import {required} from 'vuelidate/lib/validators';
import {validationMessages} from '~/plugins/validation/messages';
import {yaMapControls, yaMapOptions, yaMapSettings} from '~/plugins/settings';
import env from '@/.env.json';

import ModalAddressWrapper from '~/components/elements/modals/Address/Wrapper';
import CustomSuggestion from '~/components/elements/CustomSuggestion';
import CustomInput from '~/components/elements/CustomInput';
import Button from '~/components/elements/Button';
import {suggestionTypes} from '~/plugins/enums/suggestions';
import CustomSelect from '~/components/elements/CustomSelect';
import Spinner from '~/components/elements/LoaderSpinner';
import {buildAddress} from '~/plugins/helpers/address';

export default {
  name: 'ModalAddressEdit',
  components: {Spinner, CustomSelect, Button, CustomInput, CustomSuggestion, ModalAddressWrapper},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    btnName: {
      type: String,
      default: '',
    },
    defaultAddress: {
      type: Object,
      default: () => {},
    },
    defaultCountry: {
      type: String,
      default: env.COUNTRY,
    },
  },
  data() {
    return {
      options: { debounce: 500 },
      cities: [],
      streets: [],
      houses: [],

      address: {
        id: null,
        country: null,
        country_code: null,
        post_index: null,
        region: null,
        region_guid: null,
        area: null,
        area_guid: null,
        city: null,
        city_guid: null,
        settlement_guid: null,
        street: null,
        street_guid: null,
        house: null,
        block: null,
        flat: null,
        floor: null,
        porch: null,
        intercom: null,
        comment: null,
        geo_lat: null,
        geo_lon: null,
      },
      zoom: 11,
      coords: null,
      yaMapSettings,
      yaMapControls,
      yaMapOptions,
      submitPending: false,

      inputAddress: '',
    }
  },
  validations: {
    address: {
      city: {
        required,
      },
      house: {
        required,
      },
    },
  },
  computed: {
    ...mapState('geolocation', ['selectedCity', 'selectedCountry']),
    ...mapGetters({
      getSelectedCityCoords: 'geolocation/getSelectedCityCoords',
      getCountriesList: 'geolocation/getCountriesList',
    }),

    isShowModal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    btnText() {
      return this.btnName || 'Сохранить';
    },
    computedCoords() {
      return this.coords || this.getSelectedCityCoords;
    },
    mapSettings() {
      return { ...this.yaMapSettings };
    },
    mapOptions() {
      return {
        ...this.yaMapOptions,
        zoomControlSize: 'small',
        zoomControlFloat: 'none',
        zoomControlPosition: {
          top: '30px',
          left: '10px',
        },
      };
    },
    mapControls() {
      return [ ...this.yaMapControls ];
    },
    cityError() {
      if (this.$v.address.city.$dirty && !this.$v.address.city.required)
        return validationMessages.ERROR_REQUIRED;

      return ''
    },
    currentCountry: {
      get() {
        return this.selectedCountry
      },
      set(value) {
        this.setSelectedCountry(value)
      },
    },
  },
  watch: {
    defaultAddress() {
      this.init()
    },
  },
  beforeMount() {
    this.init()
  },
  methods: {
    ...mapActions({
      geolocateAddress: 'geolocation/geolocateAddress',
      getEmexGuid: 'emex/getEmexGuid',
    }),
    ...mapMutations({
      setSelectedCountry: 'geolocation/SET_SELECTED_COUNTRY',
    }),

    closeModalHandler() {
      this.$emit('close');
      this.isShowModal = false
      this.address = null
    },

    findAddressById(query = '') {
      return this.$api.geolocation.findAddressById({
        query,
        count: 1,
      })
    },
    findAddress(type, query, count, locations = {}) {
      const country = { country_iso_code: this.currentCountry.value }

      return this.$api.geolocation.findAddressDadata({
        query,
        count,
        locations: [{
          ...country,
          ...locations,
        }],
        from_bound: { value: type },
        to_bound: { value: 'flat' },
      })
    },

    onMapClick(e) {
      const coords = e.get('coords');
      this.setMarker(coords);
    },
    onDragMapMarker(e) {
      const coords = e.get('target').geometry.getCoordinates();
      this.setMarker(coords);
    },
    async setMarker(coords) {
      const data = {
        lat: coords[0],
        lon: coords[1],
        radius_meters: 100,
      };
      const result = await this.geolocateAddress(data);
      if (result.length) {
        const selected = result[0]

        if (selected) {
          this.applyAddress(null, selected);
        }
      }
    },

    async onCityInputChange(query) {
      try {
        // return the matching countries as an array
        const { suggestions } = await this.findAddress(suggestionTypes.REGION, query || this.address.value, 20);
        this.cities = suggestions;
      } catch (error) {
        console.log(error)
        return null;
      }
    },

    async onCitySelected(selected) {
      try {
        const { suggestions } = await this.findAddress(suggestionTypes.CITY, selected.unrestricted_value, 1);
        this.zoom = 11;
        setTimeout(() => {
          this.applyAddress('', suggestions[0]);
        }, 0);
      } catch (error) {
        console.log(error)
      }
    },
    applyAddress(type, value) {
      const address = { ...this.address };

      this.inputAddress = value.value

      this.applyCity(value, address);
      this.applyStreet(value, address);
      this.applyHouse(value, address);

      if (value.data.postal_code) address.post_index = value.data.postal_code;
      this.applyCoordinates(value, address);
      this.address = address;
    },
    applyCity(value, address) {
      if (value) {
        address.country_code = value.data.country_iso_code;

        address.region = value.data.region;
        address.region_type_full = value.data.region_type_full;
        address.region_guid = value.data.region_fias_id;

        address.area = value.data.area_with_type || value.data.area;
        address.area_guid = value.data.area_fias_id;

        address.city = value.data.city || value.data.settlement;
        address.city_type_full = value.data.city_type_full || value.data.settlement_type_full

        address.city_guid = value.data.city_fias_id;
        address.settlement_guid = value.data.settlement_fias_id;
      }
    },
    applyStreet(value, address) {
      if (value) {
        address.street = value.data.street_with_type || value.data.street;
        address.street_guid = value.data.street_fias_id;
      } else {
        address.street = null;
        address.street_guid = null;
      }
    },
    applyHouse(value, address) {
      if (value) {
        address.street = value.data.street_with_type || value.data.street;
        address.street_guid = value.data.street_fias_id;

        address.house = value.data.house_type
          ? `${value.data.house_type} ${value.data.house}`
          : value.data.house;

        address.block = value.data.block_type
          ? `${value.data.block_type} ${value.data.block}`
          : value.data.block;
      } else {
        address.house = null;
        address.block = null;
      }
    },
    applyCoordinates(value, address) {
      this.coords = []

      if (value) {
        if (value.data.geo_lat && value.data.geo_lon) {
          address.geo_lat = value.data.geo_lat;
          address.geo_lon = value.data.geo_lon;
        }
        this.coords.push(...[Number(value.data.geo_lat), Number(value.data.geo_lon)])
      } else {
        this.coords.push(...this.getSelectedCityCoords)
      }
    },

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.submitPending = true

      /* eslint-disable */
      const {
        id,
        country_code,
        post_index,
        region,
        region_type_full,
        region_guid,
        area,
        area_guid,
        city,
        city_type_full,
        city_guid,
        settlement_guid,
        street,
        house,
        block,
        flat,
        floor,
        porch,
        intercom,
        comment,
        geo_lat,
        geo_lon,
      } = this.address;

      const address = {
        id,
        country_code,
        region,
        region_guid,
        area,
        area_guid,
        city,
        city_guid: settlement_guid || city_guid,
        street,
        house,
        block,
        intercom,
        comment,
        geo_lat,
        geo_lon,
        porch: String(porch || ''),
        floor: String(floor || ''),
        flat: String(flat || ''),
        post_index: String(post_index || ''),
      };

      if (this.selectedCountry.value === 'KZ') {
        const { cityGuid, regionGuid } = await this.getEmexGuid({
          region: {
            name: region,
            type: region_type_full,
          },
          city: {
            name: city,
            type: city_type_full,
          }
        })

        address.region_guid = regionGuid
        address.city_guid = cityGuid
      }

      this.submitPending = false

      /* eslint-enable */
      this.$emit('save', address);
      this.closeModalHandler();
    },

    setDefaultCountry(address) {
      this.inputAddress = buildAddress(address)

      const code = address?.country_code || this.defaultCountry
      const item = this.getCountriesList.find(item => item.value.toLowerCase() === code.toLowerCase())

      if (item) {
        // this.selectedCountry = item
        this.setSelectedCountry(item)
      }
    },

    async init() {
      let initAddress = null

      if (this.selectedCity?.fias_id) {
        /* eslint-disable */
        const { geo_lat, geo_lon, fias_id: city_guid, name } = this.selectedCity;
        initAddress = { geo_lat, geo_lon, city_guid, city: name };
        this.zoom = 11;
        /* eslint-enable */
      }

      if (this.defaultAddress?.city_guid) {
        this.zoom = 17;
        initAddress = { ...this.defaultAddress };
      }

      try {
        if (initAddress) {
          /* eslint-disable */
          const { geo_lat, geo_lon, city_guid } = initAddress;

          if (geo_lat && geo_lon) {
            this.coords = [Number(geo_lat), Number(geo_lon)];
          }

          await this.onCitySelected({ unrestricted_value: initAddress.city })
          /* eslint-enable */
        }
      } catch (error) {
        console.log(error)
      }

      this.setDefaultCountry(initAddress)


      Object.assign(this.address, initAddress);
    },

    onCityMounted(el) {
      setTimeout(() => {
        const { input } = el.$refs;
        input && input.focus();
      }, 300);
    },
  },
}
</script>
